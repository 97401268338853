import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

export const useListenChangedRoutes = () => {
  const navigate = useNavigate();
  const [path, setPath] = useState<string | number>('');

  const sendRouteToInsumosApp = (newRoute: string) => {
    const event = new CustomEvent('navigate-hostapp', {
      detail: { path: newRoute }, // Pasar la nueva ruta
    });
    window.dispatchEvent(event);
  };

  useEffect(() => {
    // Monitorear cambios en la historia del navegador
    const handleRouteChange = (event: any) => {
      const changedPath = `/insumos${event.detail.path}`;
      setPath(changedPath);
    };

    window.addEventListener(
      'insumos-comprador-route-changed',
      handleRouteChange,
    );

    return () => {
      window.removeEventListener(
        'insumos-comprador-route-changed',
        handleRouteChange,
      );
    };
  }, [path]);

  const handleNavigate = (url: string) => {
    if (url === '/insumos/cotizaciones' && path !== url)
      sendRouteToInsumosApp('/cotizaciones');
    navigate(url);
  };

  return {
    handleNavigate,
  };
};
