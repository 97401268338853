import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { Box, Select, SelectChangeEvent } from '@mui/material';
import CustomTooltip from 'components/common/tooltips';
import { P4Ellipsis } from 'components/common/typography/styles';
import { useStores } from 'store/root-store/root-store-context';
import { Cuenta } from 'store/session-store/userModel';
import AlborIcon from 'assets/icons/albor_icon.png';
import FinnegansIcon from 'assets/icons/finnegans_icon.png';
import TablerPoint from 'assets/icons/tabler_point_icon.png';
import { ItemSelectContainer, StyledAccountSelector } from './index.styled';
import api from "../../../../services/api/api";

const getIconAccount = (domain: string, isExternal: string) => {
  if (isExternal === 'Externo') {
    return (
      <img
        width="16px"
        height="16px"
        loading="lazy"
        src={TablerPoint}
        alt="External"
      />
    );
  }
  if (domain.includes('alboragro')) {
    return <img loading="lazy" src={AlborIcon} alt="Albor" />;
  }

  return <img loading="lazy" src={FinnegansIcon} alt="Finnegans" />;
};

const AccountsDropdown: React.FC = () => {
  const navigate = useNavigate();
  const {
    sessionStore,
    sessionStore: { currentUser },
    fieldsStore,
  } = useStores();

  const getInsumosToken = async () => {
    const res = await api.auth.getTokenInsumos();
    localStorage.setItem('insumos-token', res.sessionToken);
  }

  const handleChange = async (event: SelectChangeEvent) => {
    getInsumosToken();
    fieldsStore.setFields([]);
    sessionStore.setCurrentAccount(parseInt(event.target.value, 10));
    navigate('/', { replace: true });
  };
  return (
    <>
      {currentUser &&
        currentUser.misCuentas &&
        currentUser.misCuentas.length > 0 && (
          <CustomTooltip
            title={
              currentUser.misCuentas.find(
                (account: Cuenta) => account.id === currentUser?.cuentaActual,
              )?.dominio || ''
            }
            arrowLocation="top"
          >
            <Select
              inputProps={{ readOnly: currentUser.misCuentas.length === 1 }}
              sx={{
                '& .MuiSelect-icon': {
                  display:
                    currentUser.misCuentas.length === 1 ? 'none' : 'flex',
                },
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              defaultValue={
                currentUser
                  ? currentUser && currentUser?.cuentaActual?.toString()
                  : ''
              }
              onChange={handleChange}
              input={<StyledAccountSelector />}
            >
              {currentUser.misCuentas.map((account: Cuenta) => (
                <ItemSelectContainer
                  disabled={account.id === currentUser?.cuentaActual}
                  value={account.id}
                  key={`menu-account-${account.id}`}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    gap="10px"
                    width={{
                      xs: '240px',
                      mobileM: 'auto',
                    }}
                  >
                    {account.dominio &&
                      getIconAccount(account.dominio, account.origen)}
                    <P4Ellipsis style={{ color: 'inherit' }}>
                      {account.dominio}
                    </P4Ellipsis>
                  </Box>
                </ItemSelectContainer>
              ))}
            </Select>
          </CustomTooltip>
        )}
    </>
  );
};

export default observer(AccountsDropdown);
