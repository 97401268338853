import { isDate, parse } from 'date-fns';
import { mapValues, trim } from 'lodash';
import * as yup from 'yup';
import { Strings } from '../constants/strings';
import { checkFileSize } from './common';

const phoneRegExp = /^[0-9]{1,20}$/;
/* eslint-disable */
const passwordRegExp =
  /^.*(?=.{8,})(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\!\@\#\$\%\^\&\*\(\)\-\=\_\+\`\~\.\,\<\>\/\?\;\:\'\"\\\|\[\]\{\}]).*$/;
const numberRegExp = /^[0-9.,]*$/;

const emailCommonSchema = yup
  .string()
  .email(Strings.inputs.email.error)
  .test(
    'is-lowercase',
    Strings.inputs.emailMayus.error,
    (value) => value === value?.toLowerCase(),
  );
const requiredError = Strings.inputs.requiredError.error;
const passwordSchema = yup
  .string()
  .matches(passwordRegExp, Strings.inputs.password.error);
const passwordCheckListSchema = (value: any) =>
  yup.string().lowercase().max(8).min(3).uppercase().test(value);
const commonStringValidation = yup
  .string()
  .min(3, Strings.inputs.min3Error.error);
const phoneNumberCommonSchema = yup
  .string()
  .matches(phoneRegExp, Strings.inputs.phoneNumber.error);
const textAsNumberSchema = yup.string().matches(numberRegExp, requiredError);

export const defaultConfirmPassword = (fieldName: string) =>
  yup
    .string()
    .oneOf([yup.ref(fieldName), undefined], Strings.inputs.password.mismatch);

const FileSchema = yup.mixed().required(requiredError);
export const CuitSchema = yup.object().shape({
  cuitProveedor: yup
    .string()
    .matches(
      /^(20|23|24|25|26|27|30|33|34)-(\d{8})-(\d{1})$/,
      'El CUIT ingresado no es válido. Revisalo y volvé a intentarlo.',
    ),
});
const ContactSchema = yup.object().shape({
  textarea: yup
    .string()
    .required(requiredError)
    .min(10, Strings.inputs.min10Error.error)
    .max(5000, Strings.inputs.max5000Error.error),
});

const newCompanySchema = yup.object().shape({
  estatuto: yup
    .mixed()
    .test('estatuto', 'El archivo no puede pesar mas de 5MB', (file: File) => {
      return checkFileSize(file.size);
    }),
});

const FieldSchema = yup.object().shape({
  nombre: commonStringValidation,
  provincia: yup.string().required(requiredError),
  partido: yup.string().required(requiredError),
  localidad: yup.string().required(requiredError),
});

const MyDataSchema = yup.object().shape({
  email: emailCommonSchema.required(requiredError),
  firstName: commonStringValidation.required(requiredError),
  lastName: commonStringValidation.required(requiredError),
  telefono: phoneNumberCommonSchema.nullable(),
});

const RegisterSchema = yup.object().shape({
  nombre: commonStringValidation.required(requiredError),
  apellido: commonStringValidation.required(requiredError),
  password: passwordSchema.required(requiredError),
  passwordRepeat: defaultConfirmPassword('password').required(requiredError),
});

const RegisterFromErpSchema = yup.object().shape({
  nombre: commonStringValidation.required(requiredError),
  apellido: commonStringValidation.required(requiredError),
});

const SignatorySchema = yup.object().shape({
  email: emailCommonSchema.required(requiredError),
  nombre: commonStringValidation.required(requiredError),
  apellido: commonStringValidation.required(requiredError),
  domicilio: commonStringValidation.required(requiredError),
  telefono: phoneNumberCommonSchema.required(requiredError),
  dniFrente: FileSchema,
  dniDorso: FileSchema,
  firma: FileSchema,
});

/**
 * @description Datos de contacto: Mis empresas
 *
 * Tomar de referencia para los demás formularios de contacto
 */

const commonDataContactValidations = {
  email: emailCommonSchema.required(requiredError),
  nombre: commonStringValidation.required(requiredError),
  apellido: commonStringValidation.required(requiredError),
  telefono: phoneNumberCommonSchema.required(requiredError),
  cargo: commonStringValidation.required(requiredError),
};

const ContactDataSchema = yup.object().shape({
  ...commonDataContactValidations,
});

const ContactDataFinancingSchema = yup.object().shape({
  cuitApplicant: yup
    .number()
    .required(Strings.forms.finance.error.requiredError),
  isInGroup: yup.boolean(),
  cuits: yup.array().when('isInGroup', {
    is: true,
    then: yup
      .array()
      .min(1, Strings.forms.finance.error.atLeast1)
      .required(Strings.forms.finance.error.requiredCheck),
  }),
  ...commonDataContactValidations,
});

const DarumaSchema = yup.object().shape({
  cuitApplicant: yup.number(),
  areaTelefono: phoneNumberCommonSchema.nullable(),
  payTaxes: yup.string(),
  pais: yup.string().when('payTaxes', {
    is: true,
    then: yup.string().required(Strings.inputs.paísError.error),
    otherwise: yup.string(),
  }),
  nif: yup.string().when('payTaxes', {
    is: false,
    then: yup.string().required(Strings.inputs.nifError.error),
    otherwise: yup.string(),
  }),
  ...commonDataContactValidations,
});

const InviteMemberSchema = yup.object().shape({
  email: emailCommonSchema.required(requiredError),
  rol: yup.string().required(requiredError),
});

const ChangePasswordSchema = yup.object().shape({
  oldPassword: yup.string().required(requiredError),
  newPassword: passwordSchema.required(requiredError),
  repeatNewPassword:
    defaultConfirmPassword('newPassword').required(requiredError),
});

const CreatePasswordSchema = yup.object().shape({
  newPassword: passwordSchema.required(requiredError),
  repeatNewPassword:
    defaultConfirmPassword('newPassword').required(requiredError),
});

const RecoverAccountSchema = yup.object().shape({
  email: emailCommonSchema.required(requiredError),
});

const NewPasswordValidationSchema = yup.object().shape({
  password: passwordSchema.required(requiredError),
  repeatNewPassword: defaultConfirmPassword('password').required(requiredError),
});
const HectaresSchema = yup.object().shape({
  hectareasAprobadas: yup.number(),
  hectareasDeclaradas: yup
    .number()
    .required(() => Strings.inputs.hectareas.declaredRequired)
    .transform((_value, originalValue) =>
      Number(originalValue.replace(/,/, '.')),
    )
    .min(1, Strings.inputs.hectareas.declaredCannotLessThanOne)
    .max(
      yup.ref('hectareasAprobadas'),
      Strings.inputs.hectareas.declaredCannotBeGreater,
    )
    .typeError(() => Strings.inputs.hectareas.declaredCannotBeString),
});

const LoginSchema = yup.object().shape({
  email: emailCommonSchema.required(requiredError),
  password: yup.string().required(requiredError),
});

const RegisterExternalSchema = yup.object().shape({
  email: emailCommonSchema.required(requiredError),
  password: passwordSchema.required(requiredError),
  passwordRepeat: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Las contraseñas no coinciden')
    .required(requiredError),
});

const RequestAccountSchema = yup.object().shape({
  names: yup.string().required(requiredError),
  lastNames: yup.string().required(requiredError),
  email: emailCommonSchema.required(requiredError),
  phone: phoneNumberCommonSchema,
  domain: yup.string().required(requiredError),
});

const ClientTypeSchema = yup.object().shape({
  client: yup
    .string()
    .nullable()
    .required(Strings.forms.finance.error.requiredRadio),
});

const InsuranceFeedbackSchema = yup.object().shape({
  aceptarPropuesta: yup.boolean(),
  codigoMotivo: yup.string(),
  descripcionMotivo: yup.string().when('codigoMotivo', {
    is: 'otro',
    then: yup.string().required(requiredError), // TODO: add min length
  }),
});

const DarumaPhisycProfileSchema = yup.object().shape({
  origenFondos: yup.string().required(),
  descripcionOrigenFondos: yup.string().when('origenFondos', {
    is: '4',
    then: yup.string().required(requiredError),
  }),
  ingresoMensual: yup.number().required(),
  patrimonioDisponible: yup.number().required(),
  aceptoDaruma: yup.boolean().required(),
  aceptoAllaria: yup.boolean().required(),
  aceptoArgenpymes: yup.boolean().required(),
});

const MontoSchema = yup.lazy((obj) =>
  yup.object(
    mapValues(obj, (value, key) =>
      yup
        .string()
        .matches(
          /^(?:[0-9]){0,}((?:[,.]{1})(?:[0-9]){1,})?$/,
          'error en el valor ingresado',
        )
        .test(
          'test de valor mínimo',
          'el valor ingresado no puede ser menor a 0.01',
          (value) =>
            parseFloat(value === undefined ? '0' : value.replace(',', '.')) >=
            0.01,
        )
        .test(
          'test de valor máximo',
          'el valor ingresado no puede ser mayor a 9999',
          (value) =>
            parseFloat(value === undefined ? '0' : value.replace(',', '.')) <=
            9999,
        )
        .required(),
    ),
  ),
);

const RindeSchema = yup.lazy((obj) =>
  yup.object(
    mapValues(obj, (value, key) =>
      yup
        .string()
        .matches(
          /^(?:[0-9]){0,}((?:[,.]{1})(?:[0-9]){1,})?$/,
          'error en el valor ingresado',
        )
        .test(
          'test de valor mínimo',
          'el valor ingresado no puede ser menor a 1',
          (value) =>
            parseFloat(value === undefined ? '0' : value.replace(',', '.')) >=
            1,
        )
        .test(
          'test de valor máximo',
          'el valor ingresado no puede ser mayor a 99999',
          (value) =>
            parseFloat(value === undefined ? '0' : value.replace(',', '.')) <=
            99999,
        ),
    ),
  ),
);
const AmountSchema = yup.object().shape({
  products: yup.object().when('aseguradoPor', {
    is: 'por_rinde',
    then: RindeSchema,
    otherwise: MontoSchema,
  }),
  aseguradoPor: yup.string(),
});

const EspacioDeTrabajoSchema = yup.object().shape({
  dominio: yup
    .string()
    .matches(
      /^[a-zA-Z0-9]{3,50}$/,
      'No debe incluir espacios ni caracteres especiales',
    ),
});

const AccionistaSchema = yup.object().shape({
  nombre: commonStringValidation.required(requiredError),
  apellido: commonStringValidation.required(requiredError),
  domicilio: commonStringValidation.required(requiredError),
  paisOrigen: yup.string().required(requiredError),
  cuit: yup
    .string()
    .matches(/^\d{2}-?\d{8}-?\d{1}$/, 'El CUIT es inválido')
    .test('cuit-with-or-without-dashes', 'El CUIT es inválido', (value) => {
      if (!value) return false;

      const pattern = /^(\d{2})(\d{8})(\d{1})$/;
      const [, prefix, middle, suffix] =
        value.replace(/-/g, '').match(pattern) || [];
      return Boolean(
        prefix &&
          middle &&
          suffix &&
          (prefix + '-' + middle + '-' + suffix === value ||
            prefix + middle + suffix === value),
      );
    })
    .required(requiredError),
  profesion: commonStringValidation.required(requiredError),
  fechaNacimiento: yup
    .date()
    .typeError('El formato de la fecha es incorrecto. Debe ser dd/mm/aaaa')
    .transform((value, originalValue) => {
      const parsedDate = isDate(originalValue)
        ? originalValue
        : parse(originalValue, 'dd/MM/yyyy', new Date());
      return parsedDate;
    })
    .min(
      new Date(1910, 0, 1),
      'La fecha de nacimiento no puede ser menor a 01/01/1910',
    )
    .max(new Date(), 'La fecha de nacimiento no puede ser mayor a hoy')
    .required(requiredError),
  estadoCivil: commonStringValidation.required(requiredError),
  participacion: yup
    .string()
    .matches(
      /^(?:[0-9]){0,}((?:[,.]{1})(?:[0-9]){1,2})?$/,
      'Porcentaje de participación inválido',
    )
    .test(
      'test de valor mínimo',
      'El porcentaje de participación no puede ser menor que 10',
      (value) =>
        parseFloat(value === undefined ? '0' : value.replace(',', '.')) >= 10,
    )
    .test(
      'test de valor máximo',
      'El porcentaje de participación no puede ser mayor que 100',
      (value) =>
        parseFloat(value === undefined ? '0' : value.replace(',', '.')) <= 100,
    )
    .required(requiredError),
});

const CreateLoteSchema = (names: string[]) => {
  return yup.object().shape({
    name: commonStringValidation
      .required(requiredError)
      .test('is-unique', 'El nombre del lote ya está en uso', (value) => {
        return (
          Boolean(value) && !names.includes(trim(value).toLocaleLowerCase())
        );
      }),
  });
};

const CreateFieldSchema = yup.object().shape({
  name: commonStringValidation.required(requiredError),
});

export {
  FieldSchema,
  SignatorySchema,
  InviteMemberSchema,
  FileSchema,
  ChangePasswordSchema,
  MyDataSchema,
  HectaresSchema,
  NewPasswordValidationSchema,
  RecoverAccountSchema,
  RegisterSchema,
  LoginSchema,
  RegisterExternalSchema,
  ContactDataSchema,
  ContactDataFinancingSchema,
  ClientTypeSchema,
  InsuranceFeedbackSchema,
  AmountSchema,
  RegisterFromErpSchema,
  DarumaSchema,
  RequestAccountSchema,
  DarumaPhisycProfileSchema,
  ContactSchema,
  AccionistaSchema,
  CreateFieldSchema,
  CreateLoteSchema,
  EspacioDeTrabajoSchema,
  CreatePasswordSchema,
  newCompanySchema,
};
