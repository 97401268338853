import React, { useEffect, useRef } from 'react';
import { LazyIntegratedApp } from 'screens/lazyRoutes';

const InsumosWrapper: React.FC = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    const mainLayout = document.querySelector('main');
    const body = document.body;

    if (wrapper && mainLayout) {
      body.style.overflow = 'hidden';

      mainLayout.style.height = '100vh';
      mainLayout.style.overflow = 'hidden';

      wrapper.style.height = 'calc(100% - 30px)';
      wrapper.style.overflow = 'auto';
      wrapper.style.paddingBottom = '20px';
    }

    return () => {
      if (mainLayout) {
        mainLayout.style.height = '';
        mainLayout.style.overflow = '';
      }
      body.style.overflow = '';
      if (wrapper) {
        wrapper.style.height = '';
        wrapper.style.overflow = '';
        wrapper.style.paddingBottom = '';
      }
    };
  }, []);

  return (
    <div
      ref={wrapperRef}
      style={{
        width: '100%',
        height: 'calc(100% - 30px)',
        paddingBottom: '20px',
      }}
    >
      <LazyIntegratedApp />
      <div id="insumos-comprador"></div>
    </div>
  );
};

export default InsumosWrapper;
